import { MouseEventHandler } from 'react'
import * as React from 'react'

type Props = {
  onClick: MouseEventHandler,
  text: string,
}

const ButtonTSX = ({ onClick, text }: Props) => (
  <button onClick={onClick}>
    {text}
  </button>
)

export default ButtonTSX