import React, { useContext, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";

import SolicitacoesTable from "../../../components/solicitacoes/table";
import { VerticalContainer } from "../../../components/styled";
import history from "../../../routes/history";
import {
  getSolicitacoes,
  deleteSolicitacao,
} from "../../../services/solicitacoes";
import { UserContext } from "../../../context";

const { Search } = Input;

export default function ListSolicitacoes() {
  const [filter, setFilter] = useState("");
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);
  const getSolicitacoesPaginated = async () => {
    setLoading(true);
    try {
      const solicitacoesData = await getSolicitacoes(page, filter);
      setSolicitacoes(solicitacoesData.data);
      setTotal(solicitacoesData.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSolicitacoesPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteSolicitacao(record.id);
    await getSolicitacoesPaginated();
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar solicitação por paciente, profissional, especialidade..."
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        {user?.type !== "CLINICA" && (
          <>
            <Button
              type="dashed"
              onClick={() => {
                history.push("/adicionar-solicitacao");
              }}
              style={{ width: "100%!important" }}
              block
            >
              <PlusOutlined />
              Adicionar solicitação
            </Button>
          </>
        )}

        <br />
        <SolicitacoesTable
          loading={loading}
          handleTableChange={handleTableChange}
          solicitacoes={solicitacoes}
          total={total}
          confirm={confirm}
        />
      </VerticalContainer>
    </>
  );
}
