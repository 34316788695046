import { notification } from "antd";
import moment from "moment";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const ERROR = "Não foi possível atualizar a solicitação";

const normalizeSolicitacao = (values) => {
  values.clinica_id = JSON.parse(values.clinica).id;
  values.paciente_id = JSON.parse(values.paciente).id;
  values.profissional_id = JSON.parse(values.profissional).id;
  values.profissional_clinica_id = JSON.parse(values.profissional_clinica).id;
  values.unidade_id = JSON.parse(values.unidade).id;
  values.data = moment(values.data).format("Y-M-D HH:mm");
  delete values.paciente;
  delete values.clinica;
  delete values.profissional;
  delete values.profissional_clinica;
  delete values.unidade;
  delete values.created_by;
  delete values.updated_by;
  delete values.marcador;
};

const onGetSolicitacao = (values) => {
  values.clinica = JSON.stringify(values.clinica);
  values.paciente = JSON.stringify(values.paciente);
  values.profissional = JSON.stringify(values.profissional);
  values.profissional_clinica = JSON.stringify(values.profissional_clinica);
  values.unidade = JSON.stringify(values.unidade);
  values.data = moment(values.data, "Y-M-D HH:mm");
};

const registerSolicitacao = async (values) => {
  try {
    normalizeSolicitacao(values);
    const { data } = await api.post("solicitacoes", values);
    history.push("/solicitacoes");
    notification.open({
      message: "Solicitação adicionada com sucesso!",
    });
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

const deleteSolicitacao = async (id) => {
  try {
    await api.delete(`solicitacoes/${id}`);
    notification.open({
      message: "Solicitação deletada com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error?.response?.data?.error,
    });
  }
};

const getSolicitacoes = async (page, filter = "") => {
  const { data: solicitacaos } = await api.get(
    `solicitacoes?page=${page}&filter=${filter}`
  );
  return solicitacaos;
};

const getSolicitacoesByPaciente = async (pacienteId, page) => {
  const { data: solicitacaos } = await api.get(
    `solicitacoes?page=${page}&paciente_id=${pacienteId}`
  );
  return solicitacaos;
};

const getSolicitacoesByProfissional = async (profissionalId, page) => {
  const { data: solicitacaos } = await api.get(
    `solicitacoes?page=${page}&profissional_id=${profissionalId}`
  );
  return solicitacaos;
};
const getSolicitacoesByClinica = async (clinicaId, page) => {
  const { data: solicitacaos } = await api.get(
    `solicitacoes?page=${page}&clinica_id=${clinicaId}`
  );
  return solicitacaos;
};

const getSolicitacao = async (id) => {
  try {
    const { data } = await api.get(`solicitacoes/${id}`);
    onGetSolicitacao(data);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

const abrirComprovanteSolicitacao = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/solicitacoes/comprovante/${id}`;
  window.open(url, "PRINT", "height=720,width=900");
};

const getByCurrentMonthAndPaciente = async (id, procedimentoId) => {
  try {
    const { data } = await api.get(
      `solicitacoes/paciente/${id}/${procedimentoId}`
    );
    data.forEach((solicitaao) => onGetSolicitacao(solicitaao));
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

const updateSolicitacao = async (solicitacao, values) => {
  try {
    normalizeSolicitacao(values);
    values.id = solicitacao.id;
    await api.put("solicitacoes", values);
    history.push("/solicitacoes");
    notification.open({
      message: "Solicitacão atualizada com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

export {
  registerSolicitacao,
  deleteSolicitacao,
  getSolicitacoes,
  updateSolicitacao,
  getSolicitacao,
  getSolicitacoesByPaciente,
  abrirComprovanteSolicitacao,
  getByCurrentMonthAndPaciente,
  getSolicitacoesByProfissional,
  getSolicitacoesByClinica,
};
