import React, { useState } from 'react';

import { Button, Col, Form, Row } from 'antd';

import { FormItemRangePicker } from '../../../components/ant-wrapper/form-item';
import { gerarRelatorioProcedimentos } from '../../../services/relatorios';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 }
};

export default function RelatorioProcedimentos() {
  const [form] = Form.useForm();

  const handleDistritos = async (values) => {
    await gerarRelatorioProcedimentos(values);
  };

  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="register"
        onFinish={handleDistritos}
        scrollToFirstError
        {...formItemLayout}
      >
        <Row>
          <Col span={8}>
            <FormItemRangePicker
              name="datas"
              label="Período de datas solicitadas"
              required
            />
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Gerar relatórios dos procedimentos
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
