import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Row, Select } from "antd";

import {
  FormItemSelect,
  FormItemInput,
  FormItemRangePicker,
  FormItemCheckbox,
} from "../../../components/ant-wrapper/form-item";
import { getClinicas } from "../../../services/clinicas";
import { getProfissionalClinicas } from "../../../services/clinicas/profissional";
import { getPacientes } from "../../../services/pacientes";
import { getProfissionais } from "../../../services/profissional";
import { gerarRelatorioSolicitacao } from "../../../services/relatorios";
import { getUnidades } from "../../../services/unidades";
import { failureNotification } from "../../../utils";
import { getProcedimentos } from "../../../services/procedimentos";

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
export default function RelatorioSolicitacoes() {
  const [form] = Form.useForm();
  const [loadingDados, setloadingDados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [clinicas, setClinicas] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [profRealizadores, setProfissionaisRealizadores] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);

  const onSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      await gerarRelatorioSolicitacao(values);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  };

  const getData = async () => {
    setloadingDados(true);
    try {
      setUnidades(await getUnidades("", "", false));
      setClinicas(await getClinicas("", "", false));
      const pacientesData = await getPacientes(0, "");
      setPacientes(pacientesData.data);
      setProfissionais(await getProfissionais("", "", false));
      const procedimentos = await getProcedimentos("", "", false);
      setProcedimentos(await getProcedimentos("", "", false));
    } finally {
      setloadingDados(false);
    }
  };

  const onChangeClinica = async (value) => {
    const clinica = JSON.parse(value);
    try {
      setProfissionaisRealizadores(
        await getProfissionalClinicas("", "", clinica, false)
      );
    } catch (err) {
      failureNotification(err.error);
    }
  };

  const cardStyle = { borderColor: "white" };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <Form
        form={form}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FormItemInput name="numero" label="Número" />
          </Col>
          <Col span={8}>
            <FormItemSelect
              name="unidade"
              label="Unidade de Saúde"
              items={unidades}
              itemsLabel={["pessoa_juridica", "nome"]}
              stringfyValue
            />
          </Col>
          <Col span={8}>
            <FormItemSelect
              name="clinica"
              label="Clinica"
              items={clinicas}
              onChangeSelect={onChangeClinica}
              itemsLabel={["pessoa_juridica", "nome"]}
              stringfyValue
            />
          </Col>
          <Col span={8}>
            <FormItemSelect
              name="paciente"
              label="Paciente"
              items={pacientes}
              onSearch={onSearch}
              itemsLabel={["pessoa_fisica", "nome"]}
              stringfyValue
            />
          </Col>
          <Col span={8}>
            <FormItemSelect
              name="profissional"
              label="Profissional"
              items={profissionais}
              itemsLabel={["pessoa_fisica", "nome"]}
              stringfyValue
            />
          </Col>

          <Col span={8}>
            <FormItemSelect
              viewMode={profRealizadores.length === 0}
              name="profissional_clinica"
              label="Profissional Realizador"
              items={profRealizadores}
              itemsLabel="profissional"
              stringfyValue
            />
          </Col>

          <Col span={8}>
            <FormItemRangePicker
              name="datas"
              label="Período de datas solicitadas"
            />
          </Col>

          <Col span={8}>
            <FormItemRangePicker
              name="datas_lancamento"
              label="Período de lançamento"
            />
          </Col>

          <Col span={8}>
            <FormItemSelect
              name="procedimento"
              label="Procedimento"
              items={procedimentos}
              itemsLabel="nome"
              stringfyValue
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <FormItemCheckbox
              name="relatorio_detalhado"
              checkboxLabel="Relatório detalhado"
              valuePropName="checked"
            />
          </Col>
        
          <Col span={4}>
            <FormItemCheckbox
              name="ordem_alfabetica"
              checkboxLabel="Ordem Alfabética"
              valuePropName="checked"
            />
          </Col>
        </Row>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {!loading ? "Enviar" : "Gerando relatório"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
